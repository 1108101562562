.button-m {
    width: 4em;
    height: 2em;
    margin: 20px;
    user-select: none;
}
.text-center {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
}
@media (max-width: 768px) {
    .button-m {
        user-select: auto;
    }
}
.bigText {
    font-size: 2em;
}