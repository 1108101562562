.sideBar {
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: #dedede;
    margin-right: 20px;
    text-align: center;
}

@media (max-width: 768px) {
    .sideBar {
        height: fit-content;
        width: 100%;
        position: sticky;
        top: 0px;
    }
}
.sideBarButton {
    border: none;
    background-color: #dedede;
    width: 100%;
    font-size: 1em;
}
.sideBarButton:hover {
    background-color: #cfcfcf;
    cursor: pointer;
    border: none
}
.element {
    margin-top: 10px;
    width: 100%-10px;
}