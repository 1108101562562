.wordInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0px;
}
#textInput {
    width: 90%;
    font-size: 20px;
}
.height {
    height: 9.8vh;
}
.sticky {
    position: sticky;
    top: 10px;
}
@media (max-width: 768px) {
    .gray:hover {
        background-color: #aaa;
    }
    #textInput {
        width: 100%
    }
}