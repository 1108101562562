#root {
  height: 100%;
}
.App {
  background-color: gray;
  display: flex;
  height: 100%;
}

@media (max-width: 768px) {
  .App {
    display: block;
    flex-direction: column;
    overflow-y: scroll
  }
}
#main {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.button:hover {
  cursor: pointer;
}
.blue {
  background-color: #1e90ff;
  color: white;
}
.blue:hover {
  background-color: #0f7fdd;
}
.red {
  background-color: #ff6347;
  color: white;
}
.red:hover {
  background-color: #dd4d30;
}
.gray {
  background-color: #aaa;
}
.gray:hover {
  background-color: #9a9a9a;
}
.flex {
  display: flex;
  justify-content: space-around;
}
.maxWidth {
  width: 100%;
}
.maxHeight {
  height: 100%;
}
.round {
  border-radius: 15px;
}
.noBorder {
  border: none;
  outline: none;
}
.center {
  text-align: center;
  justify-content: center;
}
.block {
  display: block;
}