.blueText {
    color: blue;
}
.click {
    cursor: pointer;
}
.newline {
    white-space: pre-line;
}
.overflow {
    overflow: auto;
}
.max {
    max-width: 100%;
    max-height: 100%;
}