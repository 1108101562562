#ViewWords {
    width: 100%;
    height: 90%;
    align-items: center;
    overflow: auto;
    margin-bottom: 9.8vh;
}
.center {
    text-align: center;
}
#words {
    font-size: 2em;
}
#stopInput {
    position: fixed;
    top: 10px;
}
@media (max-width: 768px) {
    #stopInput {
        top: 11%
    }
    #blank {
        height: 9.8vh;
    }
}
.newLine {
    white-space: pre-line;
}